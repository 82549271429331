@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');

html, body, #root, .app {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

body {
  all: unset;
  background-color: #f2f2f2;
  color:#22303C;
  font-family: 'Poiret One', cursive;
  text-align: center;
}

.filled {
  color: #f2f2f2;
  background:#22303C;
}

.button {
  border-radius: 5%;
  padding: 15px 40px;
  margin: 20px 0;
  font-size: 30px;
}