img {
    width: 100%;
}

button {
    all:unset;
}

h1 {
    font-size: 36px;
}

p {
    font-size: 30px;
}

.section {
    margin: 50px;
}

.app {
    display: flex;
    flex-direction: column;
}

.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
  }