.reviews-container {
    margin: 20px;
    margin-top: 150px;

    display: flex;
    flex-direction: column;
    align-items: center;

    border-top: 2px solid #22303C;
}

.review {
    margin-bottom: 20px;
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto;
  }
  
  .review-container {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  .review-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .review-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .review-info {
    margin-left: 10px;
  
  }
  
  .review-name {
    font-weight: bold;
  }
  
  .review-comment {
    line-height: 1.5;
    font-weight: 100;
  }