.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.80);
    z-index: 9999;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
  
    cursor: pointer;
  }
  
  .previous-button {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #fff;
  
    z-index: 1;
    background: none;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
  }
  
  .next-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #fff;
    z-index: 1;
    background: none;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
  }
  
  .selected-image {
    max-width: 90%;
    max-height: 90%;
  }