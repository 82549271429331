.footer-container {
    width: 100vw;
    padding: 20px 0;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

a.social {
    margin: 0 20px;
    color: #22303C;
}