.contact-container {
    margin: 20px;
    margin-top: 150px;

    display: flex;
    flex-direction: column;
    align-items: center;

    border-top: 2px solid #22303C;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 900px;
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .form-group {
    display:flex;
    flex-direction: column;
    width: 70%;
    margin: 10px 0;
  }
  
  label {
    font-weight: bold;
  }
  
  input,
  textarea {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 10px 0;
    padding: 10px;
  }
  
  textarea {
    min-height: 100px;
    resize: vertical;
    font-family: 'Helvetica', Arial, Lucida Grande, sans-serif;
  }
  
  button {
    padding: 10px 20px;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Media Query for Mobile */
  @media (max-width: 991px) {
    .contact-form {
      width: 90%;
    }
  
    input,
    textarea {
      width: 100%;
    }
  }